.homeContainer {
  min-height: 100vh;
  animation: fadein 0.5s ease-out forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.newButton {
  position: fixed;
  bottom: 10%;
  right: 15%;
}

.dataContainer {
  margin: 24px;
}
