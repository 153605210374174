.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  position: relative;
  top: 25px;
  opacity: 0;
  animation: fadein 0.5s ease-out forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
    top: 25px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.loginModule {
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.logo {
  height: 75px;
  width: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}
