.row-pending {
  background-color: #e6cc4d;
}

.row-processing {
  background-color: #3399ff;
}

.row-failed {
  background-color: #ff4d4d;
}

.row-ready {
  background-color: #5dd55d;
}

.metadata {
  text-decoration: underline;
  color: #3399ff;
  cursor: pointer;
}

.delete {
  cursor: pointer;
}

.modalBox {
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
  border: "2px solid #000";
}
